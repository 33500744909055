import revive_payload_client_rCiShSLm2X from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WLEVeyAqMv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_18tQPuDHaH from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1FrEG3yrF3 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.20.0_vite@5.3.5_vue@3.4.35/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kLfMKBRVDD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yf4yK3cYrF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Mvo1rSNFXr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_W1BtNT7Ro3 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PHjuEGQRft from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.20.0_typescript@5.5.4_vue@3.4.35/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_ALbhlQQLb5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@types+node@20.14.14_eslint@8.57.0_rollup@4.20.0_sass@1.77.8_stylelint@14.16.1_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_o4Tqesp2rn from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.4_rollup@4.20.0_vue@3.4.35/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_KUk0tUVLNW from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_rollup@4.20.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import templates_pwa_client_34bd86cc_B95xfDPfOx from "/opt/build/repo/.nuxt/templates.pwa.client.34bd86cc.ts";
import plugin_cQRb2C56pv from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_rollup@4.20.0_typescript@5.5.4_vue@3.4.35/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_NKQ3DIIoMW from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_jT8HISAb11 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
export default [
  revive_payload_client_rCiShSLm2X,
  unhead_WLEVeyAqMv,
  router_18tQPuDHaH,
  _0_siteConfig_1FrEG3yrF3,
  payload_client_kLfMKBRVDD,
  navigation_repaint_client_yf4yK3cYrF,
  check_outdated_build_client_Mvo1rSNFXr,
  chunk_reload_client_W1BtNT7Ro3,
  plugin_vue3_PHjuEGQRft,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ALbhlQQLb5,
  plugin_o4Tqesp2rn,
  nuxt_plugin_KUk0tUVLNW,
  templates_pwa_client_34bd86cc_B95xfDPfOx,
  plugin_cQRb2C56pv,
  switch_locale_path_ssr_NKQ3DIIoMW,
  i18n_jT8HISAb11,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q
]